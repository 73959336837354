@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* @import 'public/SkylarSansBold.ttf'; */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
