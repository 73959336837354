.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #ED8223;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  